<template>
   <div id="root-container" class="mainview bg-surface-500 dark:bg-surface-800">
      <div v-if="isDataLoading">
         <LoadingScreen />
      </div>
      <div v-else class="min-h-screen flex relative lg:static l-main-view-wrap">
         <SideMenu />
         <div class="min-h-screen flex flex-col relative flex-auto l-main-view">
            <TopBar @customerEditClicked="isCustomerDialogVisible = true"
               @projectEditClicked="isProjectDialogVisible = true"
               @businessGoalEmailClicked="isBusinessGoalDialogVisible = true" />
            <div class="min-h-screen flex flex-col relative flex-auto  px-8">
               <slot />
            </div>
         </div>
         <CustomerDialog :is-visible="isCustomerDialogVisible" @hideDialog="isCustomerDialogVisible = false"
            :customer-id="customerId" />
         <ProjectDialog :is-visible="isProjectDialogVisible" @hideDialog="isProjectDialogVisible = false"
            :customer-id="customerId" :project-id="projectId" />
         <BusinessGoalEmailDialog :is-visible="isBusinessGoalDialogVisible"
            @hideDialog="isBusinessGoalDialogVisible = false" />
      </div>
   </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, KeepAlive } from 'vue'

import TopBar from '@/components/nav/TopBar.vue'
import SideMenu from '@/components/nav/SideMenu.vue'

// import worker from '@/.demo/apiMock'

import { useQueryClient } from '@tanstack/vue-query'
import { useProjectsQuery, useProjectsQueryPrefetch } from '@/composables/queries/useProjectsQuery'
import { useCustomersQuery, useCustomersQueryPrefetch } from '@/composables/queries/useCustomersQuery'
import { useRecommendationsQuery, useRecommendationsQueryPrefetch } from '@/composables/queries/useRecommendationsQuery'
import { useRecentBusinessGoalsDataQuery, useRecentBusinessGoalsDataQueryPrefetch } from '@/composables/queries/useRecentBusinessGoalsData'
import { useEndpointsQuery, useEndpointsQueryPrefetch } from '@/composables/queries/useEndpointsQuery'
import { useDashboardViewsQuery, useDashboardViewsQueryPrefetch } from '@/composables/queries/useDashboardViewsQuery'

import { useRouter } from 'vue-router'

import CustomerDialog from '@/components/customers/CustomerDialog.vue'
import ProjectDialog from '@/components/projects/ProjectDialog.vue'

import BusinessGoalDialog from '@/components/business-goals/BusinessGoalDialog.vue'
import BusinessGoalEmailDialog from '@/components/business-goals/BusinessGoalEmailDialog.vue'

import LoadingScreen from '@/components/shared/LoadingScreen.vue'
import { useParams } from '~/composables/utils/useParams'

import { useProjectDetailedDeploymentsQueryPrefetch } from '~/composables/queries/useProjectDetailedDeploymentsQuery'
import { useProjectFirewallPoliciesQuery, useProjectFirewallPoliciesQueryPrefetch } from '~/composables/queries/useProjectFirewallPoliciesQuery'
import { useUserProfileQuery } from '~/composables/queries/useUserProfileQuery'
import QueryKeys from '~/vue-query/QueryKeys'
import { DeploymentInfo } from '~/api/contracts/models'
import { useProjectDeploymentInfosQueryPrefetch } from '~/composables/queries/useProjectDeploymentInfosQueryPrefetch'

import { useDiagnosticsApi } from '~/composables/api/useDiagnosticsApi'
import { useUserProjectDisplaySettingsQuery, useUserProjectDisplaySettingsQueryPrefetch } from '~/composables/queries/useUserProjectDisplaySettingsQuery'
import { useProjectAnonymizationRulesQueryPrefetch } from '~/composables/queries/useProjectAnonymizationRulesQuery'
import { useFeatureFlagsQueryPrefetch } from '~/composables/queries/useFeatureFlagsQuery'
import { useBusinessGoalsQuery, useBusinessGoalsQueryPrefetch } from '~/composables/queries/useBusinessGoalsQuery'
import { useDashboardsQuery, useDashboardsQueryPrefetch } from '~/composables/queries/useDashboardsQuery'
import { useUserProjectFirewallPolicyContextQuery } from '~/composables/queries/useUserProjectFirewallPolicyContextQuery'
import { useProjectOverviewQuery } from '~/composables/queries/useProjectOverviewQuery'
import { useUserProjectsQuery } from '~/composables/queries/useUserProjectsQuery'
import { useAvailableLocationsQuery } from '~/composables/queries/useAvailableLocationsQuery'
import { useDeploymentProvisioningStatusQuery } from '~/composables/queries/useDeploymentProvisioningStatusQuery'

export default defineComponent({
   name: 'MainView',
   components: {
      LoadingScreen,
      TopBar,
      SideMenu,
      CustomerDialog,
      ProjectDialog,
      BusinessGoalDialog,
      BusinessGoalEmailDialog
   },
   setup() {
      const queryClient = useQueryClient()

      const router = useRouter()

      const diagnosticsApi = useDiagnosticsApi()

      const { customerId, projectId } = useParams()

      watch(
         () => router.currentRoute,
         () => {
            if (process.client && router.currentRoute.value?.name?.toString()) {
               diagnosticsApi.logPageView(router.currentRoute.value.name?.toString(), { customerId: customerId.value, projectId: projectId.value })
            }

         },
         { deep: true, immediate: true }
      )


      const { data: userProfile, isLoading: userProfileQueryLoading, } = useUserProfileQuery()

      const { isLoading: projectsQueryLoading } = useProjectsQuery(customerId)
      const { isLoading: userProjectsQueryLoading } = useUserProjectsQuery()
      const { isLoading: anonymizationRulesQueryLoading } = useUserProjectDisplaySettingsQuery()
      const { isLoading: customersQueryLoading } = useCustomersQuery()

      const { isLoading: recommendationsLoading } = useRecommendationsQuery(projectId)
      const { isLoading: businessGoalsLoading } = useBusinessGoalsQuery(projectId)
      const { isLoading: dashboardsQueryLoading } = useDashboardsQuery(projectId)
      const { isLoading: dashboardViewsQueryLoading } = useDashboardViewsQuery(projectId)
      const { isLoading: endpointsQueryLoading } = useEndpointsQuery(projectId)
      const { isLoading: firewallPolicyQueryLoading } = useProjectFirewallPoliciesQuery(projectId)
      const { isLoading: recentBusinessGoalDataQueryLoading } = useRecentBusinessGoalsDataQuery(projectId)
      const { isLoading: projectOverviewLoading } = useProjectOverviewQuery(projectId)
      const { isLoading: firewallPolicyContextQueryLoading } = useUserProjectFirewallPolicyContextQuery()

      const { isLoading: availableLocationsQueryLoading } = useAvailableLocationsQuery()
      const { isLoading: deploymentProvisioningStatusQueryLoading } = useDeploymentProvisioningStatusQuery(projectId)

      const isDataLoading = computed(() => {
         if (router.currentRoute.value.path == '/') return true
         if (process.server) return true

         const result = customersQueryLoading.value || userProfileQueryLoading.value || userProjectsQueryLoading.value || projectsQueryLoading.value || anonymizationRulesQueryLoading.value

         switch (router.currentRoute.value.name) {
            case 'recommendations':
               return result || recommendationsLoading.value
            case 'business-goals':
               return result || businessGoalsLoading.value || endpointsQueryLoading.value || recentBusinessGoalDataQueryLoading.value
            case 'endpoints':
               return result || endpointsQueryLoading.value
            case 'firewall':
               return result || firewallPolicyQueryLoading.value || firewallPolicyContextQueryLoading.value
            case 'dashboard':
               return result || businessGoalsLoading.value || recommendationsLoading.value || projectOverviewLoading.value
            case 'wall-dashboard':
            case 'wall-dashboards':
               return result || dashboardsQueryLoading.value || dashboardViewsQueryLoading.value
            case 'deployments':
               return result || deploymentProvisioningStatusQueryLoading.value || availableLocationsQueryLoading.value
            default:
               return result
         }
      })

      watch(() => projectId.value, () => {
         if (projectId.value) {
            Promise.all([
               useProjectDetailedDeploymentsQueryPrefetch(queryClient, projectId),
               useProjectDeploymentInfosQueryPrefetch(queryClient, projectId)
            ]).then(() => {
               const repos = queryClient.getQueryData<Record<string, DeploymentInfo[]>[]>([QueryKeys.ProjectDeploymentInfos, projectId])

               for (let repo in repos) {
                  queryClient.setQueryData([QueryKeys.RepositoryBuilds, repo], { pages: [repos[repo]] })
               }
            })
         }
      }, { immediate: true })

      watch(() => userProfile.value, () => {
         if (router.currentRoute.value.path == '/' &&
            userProfile.value?.context!.contextCustomerId &&
            userProfile.value?.context!.contextProjectId
         ) {

            router.push({
               name: 'dashboard',
               params: {
                  customerId: userProfile.value?.context!.contextCustomerId,
                  projectId: userProfile.value?.context!.contextProjectId,
               }
            })
         }
      }, { deep: true, immediate: true })

      const isCustomerDialogVisible = ref(false)
      const isProjectDialogVisible = ref(false)
      const isBusinessGoalDialogVisible = ref(false)

      return {
         isDataLoading,
         isCustomerDialogVisible,
         isProjectDialogVisible,
         isBusinessGoalDialogVisible,
         customerId,
         projectId
      }
   }
})
</script>

<style lang="scss">
.loading-spinner-container {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
}

.l-main-view-wrap {
   @media all and (min-width: 992px) {
      margin-left: 220px;
   }
}
</style>